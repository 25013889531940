<template>
  <section class="base-page" id="mainPage">
    <section class="page-header base-shadow">
      <h3 class="header-title">
        <span class="title-icon">
          <svg-icon :iconClass="$route.meta.iconSvg"></svg-icon>
        </span>
        <span v-if="showFixedContent" :title="questionDetail.content">{{
          questionDetail.content
        }}</span>
        <span v-if="!showFixedContent">{{ $route.meta.title }}</span>
      </h3>
      <!-- <span
        class="list-btn float-r"
        @click="moreQuestionDialog = !moreQuestionDialog"
      >
        <transition name="el-fade-in-linear">
          <i
            class="el-icon-d-arrow-right"
            v-if="!moreQuestionDialog"
            title="展开问题列表"
          ></i>
          <i class="el-icon-d-arrow-left" v-else title="收起问题列表"></i>
        </transition>
      </span> -->
      <div class="quick-btn-group" v-if="showFixedContent">
        <el-button
          size="small"
          :loading="canUpdateChecking"
          @click="handleEditQuestion"
          icon="el-icon-edit"
          >{{ canUpdateChecking ? "权限检验中..." : "修改" }}</el-button
        >
        <el-button
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          size="small"
          type="primary"
          plain
          @click="handleAnswerQuestion"
          icon="el-icon-edit"
          >写回答</el-button
        >
      </div>
    </section>
    <section class="page-container" id="pageContainer">
      <section class="page-content">
        <section class="question-box base-shadow" ref="questionBox">
          <h4 class="question-content">
            <span class="question-type">【{{ questionDetail.typeName }}】</span>
            {{ questionDetail.content }}
          </h4>
          <div
            class="question-img-list"
            v-if="questionDetail.files && questionDetail.files.length > 0"
          >
            <template v-for="item in questionDetail.files">
              <FilesItem
                :item="item"
                :showFileName="false"
                :showFileDesc="false"
                :showCheckBox="false"
                :key="item.id"
                class="mt-r"
              />
            </template>
          </div>
          <div class="question-control">
            <el-button
              size="small"
              :loading="canUpdateChecking"
              @click="handleEditQuestion"
              icon="el-icon-edit"
              >{{ canUpdateChecking ? "权限检验中..." : "修改" }}</el-button
            >
            <el-button
              v-if="
                checkPermission([
                  'CLIENT_ADMIN',
                  'CLIENT_GROUP_ADMIN',
                  'CLIENT_USER',
                ])
              "
              type="primary"
              plain
              size="small"
              @click="handleAnswerQuestion"
              icon="el-icon-edit"
              >写回答</el-button
            >
          </div>
        </section>
        <AnswerList
          ref="answerList"
          :questionId="questionId"
          @handleEdit="answerItemEdit"
          @writeAnswer="handleAnswerQuestion"
        />
      </section>
      <el-collapse-transition>
        <section
          class="side-question-box base-shadow"
          v-show="moreQuestionDialog"
        >
          <QuickQuestionList />
        </section>
      </el-collapse-transition>
    </section>
    <!-- <el-drawer
      title="更多问题"
      :visible.sync="moreQuestionDialog"
      direction="rtl"
      append-to-body
      size="50%"
    >
      <QuickQuestionList />
    </el-drawer> -->
    <el-dialog
      :title="isEmprty(questionId) ? '新增问题' : '修改问题'"
      :visible.sync="questionFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form
        :id="questionId"
        @cancel="onQuestionFormCancel"
        @success="onQuestionFormSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="isEmprty(answerId) ? '我的回答' : '修改答案'"
      :visible.sync="answersFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <AnswerForm
        :id="answerId"
        :questionId="questionId"
        @cancel="onAnswersFormCancel"
        @success="onAnswersFormSuccess"
      />
    </el-dialog>
  </section>
</template>

<script>
import { ShowApiError } from "@/request/error";
import { GetQuestionsById, GetUpdateCheck } from "./api/question";
import Form from "./components/Form";
import AnswerForm from "./components/AnswerForm";
import QuickQuestionList from "./components/QuickQuestionList";
import { isEmprty } from "@/utils/validate";
import AnswerList from "./components/AnswerList";
import commonControl from "./mixins/commonControl";
import { checkPermission } from "@/utils/auth";
import FilesItem from "@/views/filesModule/components/FilesItem";
export default {
  components: {
    Form,
    AnswerForm,
    AnswerList,
    QuickQuestionList,
    FilesItem,
  },
  mixins: [commonControl],
  data() {
    return {
      baseImgPath: "",
      questionFormDialog: false,
      answersFormDialog: false,
      questionId: null,
      answerId: null,
      moreQuestionDialog: true,
      showFixedContent: false,
      questionDetail: {},
      questionBoxHeight: 0,
      contentPage: null,
      canUpdateChecking: false,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "QuestionDetail" && this.$route.params.id) {
          this.questionId = this.$route.params.id;
          this.getQuestionDetail(this.questionId);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    getQuestionDetail(id) {
      GetQuestionsById(id)
        .then((res) => {
          this.questionDetail = res.data;
          this.initDOM();
        })
        .catch((err) => {
          ShowApiError("获取问题详情失败", err);
        });
    },
    initDOM() {
      this.$nextTick(() => {
        this.questionBoxHeight = this.$refs["questionBox"].offsetHeight;
        let that = this;
        this.contentPage = document.getElementById("pageContainer");
        this.contentPage.addEventListener("scroll", function (e) {
          if (e.target.scrollTop > that.questionBoxHeight) {
            if (!that.showFixedContent) {
              that.showFixedContent = true;
            }
          } else {
            if (that.showFixedContent) {
              that.showFixedContent = false;
            }
          }
        });
      });
    },
    handleEditQuestion() {
      this.canUpdateChecking = true;
      GetUpdateCheck(this.questionId)
        .then((res) => {
          this.canUpdateChecking = false;
          if (res.data) {
            this.questionFormDialog = true;
          } else {
            this.questionFormDialog = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          this.$message.warning("权限校验异常，请稍后再试!");
          this.canUpdateChecking = false;
        });
    },
    handleAnswerQuestion() {
      this.answerId = null;
      this.questionId = this.$route.params.id;
      this.answersFormDialog = true;
    },
    onQuestionFormCancel() {
      this.questionFormDialog = false;
    },
    onQuestionFormSuccess() {
      this.questionFormDialog = false;
      this.getQuestionDetail(this.questionId);
    },
    onAnswersFormCancel() {
      this.answerId = null;
      this.questionId = null;
      this.answersFormDialog = false;
    },
    onAnswersFormSuccess() {
      this.answersFormDialog = false;
      this.$refs["answerList"].onRefresh();
      this.contentPage.scrollTop = 0;
    },
    answerItemEdit(item) {
      this.answerId = item.id;
      this.questionId = item.questionId;
      this.answersFormDialog = true;
      this.$refs["answerList"].onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.base-page {
  position: relative;
  .header-title {
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 220px;
    position: relative;
  }
  .page-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .quick-btn-group {
      padding-top: 10px;
      text-align: right;
      position: absolute;
      top: 8px;
      right: 60px;
    }
  }
  .list-btn {
    font-size: 20px;
    color: #000;
    cursor: pointer;
    line-height: 33px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.question-img-list {
  padding-top: 15px;
  .question-img {
    width: 100px;
    height: 100px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
.page-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  .page-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 48px;
    padding-right: 360px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.3s linear;
    .question-box {
      border-radius: 10px;
      background-color: #fff;
      padding: 20px;
      .question-content {
        font-size: 22px;
        color: #000;
        text-align: left;
        line-height: 24px;
      }
      .question-type {
        color: #989898;
        text-align: left;
      }
      .question-control {
        text-align: right;
        padding-top: 15px;
      }
    }
  }
  .side-question-box {
    box-sizing: border-box;
    position: absolute;
    top: 48px;
    right: 0;
    bottom: 8px;
    width: 350px;
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
  }
}
</style>