var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"base-page",attrs:{"id":"mainPage"}},[_c('section',{staticClass:"page-header base-shadow"},[_c('h3',{staticClass:"header-title"},[_c('span',{staticClass:"title-icon"},[_c('svg-icon',{attrs:{"iconClass":_vm.$route.meta.iconSvg}})],1),(_vm.showFixedContent)?_c('span',{attrs:{"title":_vm.questionDetail.content}},[_vm._v(_vm._s(_vm.questionDetail.content))]):_vm._e(),(!_vm.showFixedContent)?_c('span',[_vm._v(_vm._s(_vm.$route.meta.title))]):_vm._e()]),(_vm.showFixedContent)?_c('div',{staticClass:"quick-btn-group"},[_c('el-button',{attrs:{"size":"small","loading":_vm.canUpdateChecking,"icon":"el-icon-edit"},on:{"click":_vm.handleEditQuestion}},[_vm._v(_vm._s(_vm.canUpdateChecking ? "权限检验中..." : "修改"))]),(
          _vm.checkPermission([
            'CLIENT_ADMIN',
            'CLIENT_GROUP_ADMIN',
            'CLIENT_USER',
          ])
        )?_c('el-button',{attrs:{"size":"small","type":"primary","plain":"","icon":"el-icon-edit"},on:{"click":_vm.handleAnswerQuestion}},[_vm._v("写回答")]):_vm._e()],1):_vm._e()]),_c('section',{staticClass:"page-container",attrs:{"id":"pageContainer"}},[_c('section',{staticClass:"page-content"},[_c('section',{ref:"questionBox",staticClass:"question-box base-shadow"},[_c('h4',{staticClass:"question-content"},[_c('span',{staticClass:"question-type"},[_vm._v("【"+_vm._s(_vm.questionDetail.typeName)+"】")]),_vm._v(" "+_vm._s(_vm.questionDetail.content)+" ")]),(_vm.questionDetail.files && _vm.questionDetail.files.length > 0)?_c('div',{staticClass:"question-img-list"},[_vm._l((_vm.questionDetail.files),function(item){return [_c('FilesItem',{key:item.id,staticClass:"mt-r",attrs:{"item":item,"showFileName":false,"showFileDesc":false,"showCheckBox":false}})]})],2):_vm._e(),_c('div',{staticClass:"question-control"},[_c('el-button',{attrs:{"size":"small","loading":_vm.canUpdateChecking,"icon":"el-icon-edit"},on:{"click":_vm.handleEditQuestion}},[_vm._v(_vm._s(_vm.canUpdateChecking ? "权限检验中..." : "修改"))]),(
              _vm.checkPermission([
                'CLIENT_ADMIN',
                'CLIENT_GROUP_ADMIN',
                'CLIENT_USER',
              ])
            )?_c('el-button',{attrs:{"type":"primary","plain":"","size":"small","icon":"el-icon-edit"},on:{"click":_vm.handleAnswerQuestion}},[_vm._v("写回答")]):_vm._e()],1)]),_c('AnswerList',{ref:"answerList",attrs:{"questionId":_vm.questionId},on:{"handleEdit":_vm.answerItemEdit,"writeAnswer":_vm.handleAnswerQuestion}})],1),_c('el-collapse-transition',[_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.moreQuestionDialog),expression:"moreQuestionDialog"}],staticClass:"side-question-box base-shadow"},[_c('QuickQuestionList')],1)])],1),_c('el-dialog',{attrs:{"title":_vm.isEmprty(_vm.questionId) ? '新增问题' : '修改问题',"visible":_vm.questionFormDialog,"append-to-body":"","close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.questionFormDialog=$event}}},[_c('Form',{attrs:{"id":_vm.questionId},on:{"cancel":_vm.onQuestionFormCancel,"success":_vm.onQuestionFormSuccess}})],1),_c('el-dialog',{attrs:{"title":_vm.isEmprty(_vm.answerId) ? '我的回答' : '修改答案',"visible":_vm.answersFormDialog,"append-to-body":"","close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.answersFormDialog=$event}}},[_c('AnswerForm',{attrs:{"id":_vm.answerId,"questionId":_vm.questionId},on:{"cancel":_vm.onAnswersFormCancel,"success":_vm.onAnswersFormSuccess}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }